const applyConfig = (config) => {
  config.settings.isMultilingual = true;
  config.settings.supportedLanguages = ['en', 'de'];
  config.settings.defaultLanguage = 'de';
  config.blocks.blocksConfig.slider.enableAutoPlay = true;

  return config;
};

export default applyConfig;
